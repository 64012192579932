import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../layouts/mainLayout';
import HeroImage from '../components/heroImage';
import Img from 'gatsby-image';
import ContactForm from '../components/contactForm';

export const query = graphql`
  query fourOhFourPage {
    fourOhFour: file(relativePath: { regex: "/404-in-carpet.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const hero = data => {
  return (
    <HeroImage
      backdrop={false}
      image={<Img fluid={data.fourOhFour.childImageSharp.fluid} />}
    />
  );
};

const NotFoundPage = ({ data }) => (
  <Layout hero={hero(data)}>
    <Helmet>
      <meta name="description" content="Page not found." />
      <title>Error 404 | Sci-Tech Carpet Cleaning</title>
    </Helmet>
    <h1 className="underlined">404 -- Page Not Found</h1>
    <p>
      The page you're looking for wasn't found. It may have been removed or the
      URL you entered might have a typo.
    </p>
    <div style={{ marginBottom: '70px' }} />
    <ContactForm />
  </Layout>
);

export default NotFoundPage;
